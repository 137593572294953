<template>
  <div class="header">
      <div class="loading" v-if="!logo" style="height: 100px;">
        <div class="c-loader"></div>
      </div>
      <img class="imgLogo" :src="logo" alt="logo hotel" v-if="logo"/>
      <div class="linha" style="width: 1px; display: flex;justify-content: center; align-items: center;">
        <div style="width: 1px; background-color: white;height: 80%;"></div>
      </div>
      <img src="/BoasVindas/logowebcheckin.svg" alt="logo web checkin" />
  </div>
</template>

<script>

  export default {
    props: ['logo'],
    data() {
      return {

      }
    },
  }
</script>

<style scoped>

.header {
  display: flex;
  background-color: #156CF7;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 0px 0px 25px 25px;
  padding: 10px;
}

img {
  margin: 10px;
  width: 120px;
}
@media only screen and (max-width: 300px){
  .linha {
    display:none!important;
    background-color: red;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-loader {
  animation: is-rotating 1s infinite;
  border: 6px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: #156CF7;
  height: 50px;
  width: 50px;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}

</style>