<template>
  <div data-app>
    <router-view/>
  </div>
</template>


<script>
  import FooterVue from './components/Footer.vue';
  import SocketioService from './services/socketio.services';
  export default {
    components: {
      FooterVue,
    },
    data() {
      return {

      }
    },
    created() {
    SocketioService.setupSocketConnection();
    },
    beforeUnmount() {
    SocketioService.disconnect();
  }
  }

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
</style>
