import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dadosHospede: null,
    imagemHotel: null,
    auto: true,
    emailWebCheckin: null,
    dadosPais: null,
  },
  getters: {
  },
  mutations: {
    storePais(state, dados) {
      state.dadosPais = dados;
    },
    storeDadosHospede(state, dados) {
      state.dadosHospede = dados;
    },
    storeImagemHotel(state, dados) {
      state.imagemHotel = dados;
    },
    storeAuto(state, dados) {
      state.auto = dados;
    },
    storeEmail(state, dados) {
      state.emailWebCheckin = dados;
    }
  },
  actions: {
  },
  modules: {
  }
})
