<template>
  <div class="footer">
    <div class="contatosContainer">
    <p style="font-size: small; font-weight: bold;">{{$t('webcheckin.contatosEdireitos.algumProblema')}} <span style="font-size:smaller; color: #156CF7;text-decoration: underline;" @click="() => modalAlerta = true">{{$t('webcheckin.contatosEdireitos.cliqueAqui')}}</span></p> 
    <v-dialog v-model="modalAlerta" width="80vw" max-width="600px" persistent>
      <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding: 10px;justify-content: center;align-items: center;text-align: center;">
        <h1 style="font-size: 20px; margin: 10px 0 10px 0">{{$t('webcheckin.contatosEdireitos.centralDeAtendimento')}}</h1>
        <p style="margin-top: 0px; font-size: small;" v-for="(contato, index) in contatos" :key="index"> {{ contato.descricao }}: {{ contato.numeroendereco }}</p>
        <button class="buttonFechar" style="width: 90%; margin-top: 10px;padding: 5px;" @click="() => this.modalAlerta = false">{{$t('webcheckin.contatosEdireitos.fechar')}}</button>
      </div>
    </v-dialog>
    </div>
    <p>{{$t('webcheckin.contatosEdireitos.direitosReservados')}}</p>
  </div>
</template>

<script>
import contatosHotelServices from '../services/ContatoHotel.services';
  export default {
    data() {
      return {
        contatos: [],
        modalAlerta: false,
      }
    },
    methods: {
    
    async buscarContatos() {
      await contatosHotelServices.buscar()
      .then((res) => {
        this.contatos = res.data;
      })
      .catch((error) => {
        console.log(error.response.message);
      })
    }
    
  },
  async mounted() {
    await this.buscarContatos();
  }
  }
</script>

<style scoped>

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  background-color: white;
}

p {
  font-size: 10px;
  color: #3F4849;
  margin-bottom: 10px;
}

.buttonFechar {
  background-color: #156CF7;
  color: white;
  border-radius: 10px;
}

.contatosContainer{
  margin-bottom: 10px;
}

</style>