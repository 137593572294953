import api from './api'

export default {
  buscar(body){
    const config = {
      headers: { 'Authorization': `${localStorage.getItem('tokenAuthorization')}`}
    };
    return api().post('/boasvindas/', body, config)
  },
  buscarTextos(body){
    const config = {
      headers: { 'Authorization': `${localStorage.getItem('tokenAuthorization')}`}
    };
    return api().post('/boasvindas/textos/', body, config)
  },
  salvarIdTitulo(body){
    const config = {
      headers: { 'Authorization': `${localStorage.getItem('tokenAuthorization')}`}
    };
    return api().post('/boasvindas/salvaridt/', body, config)
  },
}