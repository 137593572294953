import Vue from 'vue'
import VueRouter from 'vue-router'
import BoasVindas from '../views/BoasVindas.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/:idhotel/:idreserva/',
    name: 'BoasVindas',
    component: BoasVindas,
  },
  {
    path: '/webcheckin/',
    name: 'WebCheckin',
    meta: {
      requireAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WebCheckin.vue')
  },

  {
    path: '/splash',
    name: 'Splash',
    meta: {
      requireAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Splash.vue')
  },

  {
    path: '/realizarcheckin',
    name: 'RealizarCheckin',
    meta: {
      requireAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RealizarCheckin.vue')
  },
  {
    path: '/cadastrar/hospede/:idhotel',
    name: 'CadastroHospedePms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CadastroHospedePms.vue')
  },
  {
    path: '/cadastro/hospede/:idhotel',
    name: 'CadastroHospedeConfig',
    component: () => import('../views/CadastroHospedeConfig.vue')
  },
  {
    path: '/hospede/cadastrar/:idhotel',
    name: 'CadastrarHospede',
    component: () => import('../views/CadastrarHospede.vue')
  },
  {
    path: '/wizard/test/:idhotel',
    name: 'WizardTest',
    component: () => import('../views/WizardTest.vue')
  },
  {
    path: '/webcheckinhospede/:idhotel/:iddados',
    name: 'verificarHospedesAskSuite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/verificarHospedesAskSuite.vue')
  },
  {
    path: '/formulario/hospede/:idhotel',
    name: 'FormularioHospedeReserva',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FormularioHospedeReserva.vue')
  },
  {
    path: '/contrato/visualizar/:idhotel/:idpessoa',
    name: 'VisualizarContrato',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VisualizarContrato.vue')
  },
  {
    path: '/testeformulario',
    name: 'TesteValidacao',
    component: () => import(/* webpackChunkName: "TesteValidacao" */ '../views/TesteValidacao.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {

//   if (to.meta.requireAuth) {
//       const authToken = localStorage.getItem('tokenAuthorization')
//       if ((authToken)) {
//           next();
//       } else {
//           next({ name: 'BoasVindas' });
//       }

//   } else {
//       next();
//   }
// });


export default router
