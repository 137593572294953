import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import theme from './plugins/theme'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueSignaturePad from 'vue-signature-pad'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import VueSocketIO from 'vue-socket.io'
import SocketIO from "socket.io-client";
import axios from 'axios';
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.config.productionTip = false
import i18n from './i18n'
Vue.use(VueSignaturePad);
import Vuelidate from 'vuelidate';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.use(Vuelidate);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);


Vue.use(Vuetify, {
  iconfont: 'mdi',
  VueGoogleAutocomplete,
  theme
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDz7Bz_uYlW3vX4l2K42Qq4nhYUJgYcLuQ',
    libraries: 'places',
  }
});

import { BUILD_FD_API_URL } from '@/constantes.js'

// Vue.prototype.$http = axios

// axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3335' : 'https://apifichadigital.economysoftware.com.br'
    // axios.defaults.baseURL = BUILD_FD_API_URL


Vue.use(
  new VueSocketIO({
      debug: false,
      // connection: SocketIO(axios.defaults.baseURL+'/fd',{query: { tipo:'CAPTURA_DOCUMENTOS' }, autoConnect: false}),
      connection: SocketIO('http://localhost:3337' + '/fd', { query: {}, autoConnect: false }),
      vuex: {
          store,
          actionPrefix: "SOCKET_",
          mutationPrefix: "SOCKET_",
      },
      // options: [{ transports: ["websocket"] }],
  })
);

new Vue({
  router,
  store,
  sockets: {
    connect: function() {
        console.log('conectado')
    },
    disconnect: function() {
        console.log('nao conectado')
    },
  },
  vuetify: new Vuetify(),
  i18n,
  render: h => h(App)
}).$mount('#app')
